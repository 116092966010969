import "./styles.css";

import PartySocket from "partysocket";

import { runThree } from "./runThree";

declare const PARTYKIT_HOST: string;




// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});



// You can even start sending messages before the connection is open!
conn.addEventListener("message", (event) => {
  // add(`Received -> ${event.data}`);

  const msg = JSON.parse(event.data);

  if(msg.type === "mesh") {
    // debugger
    console.log("received mesh data", msg.scene);
    runThree(msg.scene);
  }
});

// Let's listen for when the connection opens
// And send a ping every 2 seconds right after
conn.addEventListener("open", () => {
  console.log("Connected!");
  console.log("Waiting for mesh data");
});


